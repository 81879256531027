import React, { useState, useEffect } from "react";
import { Menu, X, ArrowRight, Shield, Cpu, Users, PieChart, Zap, DollarSign } from "lucide-react";

export default function AIMinerHomepage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      {/* Navbar */}
      <nav
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled ? "bg-gray-900/95 backdrop-blur-sm shadow-lg" : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <span className="text-xl font-bold text-purple-400">AI<span className="text-blue-400">Miner</span></span>
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <a href="#features" className="hover:bg-gray-800 text-white px-3 py-2 rounded-md text-sm font-medium">特性</a>
                  <a href="#ecosystem" className="hover:bg-gray-800 text-white px-3 py-2 rounded-md text-sm font-medium">生态系统</a>
                  <a href="#token" className="hover:bg-gray-800 text-white px-3 py-2 rounded-md text-sm font-medium">AIM代币</a>
                  <a href="#roadmap" className="hover:bg-gray-800 text-white px-3 py-2 rounded-md text-sm font-medium">路线图</a>
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <button className="bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white font-bold py-2 px-6 rounded-lg transition-all duration-200 transform hover:scale-105">
                启动应用
              </button>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`${isMenuOpen ? "block" : "hidden"} md:hidden bg-gray-900`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a href="#features" className="block hover:bg-gray-800 text-white px-3 py-2 rounded-md text-base font-medium">特性</a>
            <a href="#ecosystem" className="block hover:bg-gray-800 text-white px-3 py-2 rounded-md text-base font-medium">生态系统</a>
            <a href="#token" className="block hover:bg-gray-800 text-white px-3 py-2 rounded-md text-base font-medium">AIM代币</a>
            <a href="#roadmap" className="block hover:bg-gray-800 text-white px-3 py-2 rounded-md text-base font-medium">路线图</a>
            <button className="mt-4 w-full bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white font-bold py-2 px-4 rounded-lg">
              启动应用
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto pt-32 pb-16 px-4 sm:pt-40 sm:pb-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">
              <span className="block">AI Miner</span>
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-500">
                分布式人工智能访问平台
              </span>
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-300">
              连接AI提供者与用户的去中心化网络，基于Internet Computer构建，
              通过AIM代币激励生态系统的增长与参与。
            </p>
            <div className="mt-10 max-w-md mx-auto sm:flex sm:justify-center md:mt-12">
              <div className="rounded-md shadow">
                <a
                  href="#getstarted"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 md:py-4 md:text-lg md:px-10"
                >
                  开始使用
                </a>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a
                  href="#learn"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  了解更多
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section id="features" className="py-20 bg-gray-800/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-500 sm:text-4xl">
              平台特性
            </h2>
            <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-300">
              AI Miner构建在Internet Computer上，提供安全、高效、去中心化的LLM API访问
            </p>
          </div>

          <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {/* Feature 1 */}
            <div className="bg-gray-900 rounded-lg p-6 shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl border border-gray-800">
              <div className="w-12 h-12 rounded-md bg-purple-500 flex items-center justify-center mb-4">
                <Shield size={24} />
              </div>
              <h3 className="text-xl font-bold text-white">去中心化访问</h3>
              <p className="mt-2 text-gray-300">
                通过Internet Computer的Canister访问各种LLM API，无需中心化中介。
              </p>
            </div>

            {/* Feature 2 */}
            <div className="bg-gray-900 rounded-lg p-6 shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl border border-gray-800">
              <div className="w-12 h-12 rounded-md bg-blue-500 flex items-center justify-center mb-4">
                <Cpu size={24} />
              </div>
              <h3 className="text-xl font-bold text-white">双向激励机制</h3>
              <p className="mt-2 text-gray-300">
                LLM API提供者和用户均可获得AIM代币奖励，形成完整生态循环。
              </p>
            </div>

            {/* Feature 3 */}
            <div className="bg-gray-900 rounded-lg p-6 shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl border border-gray-800">
              <div className="w-12 h-12 rounded-md bg-indigo-500 flex items-center justify-center mb-4">
                <Users size={24} />
              </div>
              <h3 className="text-xl font-bold text-white">无缝集成</h3>
              <p className="mt-2 text-gray-300">
                与DeepSeek等顶级LLM提供商集成，为用户提供高质量AI服务。
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="ecosystem" className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-500 sm:text-4xl">
              生态系统运作方式
            </h2>
            <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-300">
              了解AI Miner如何连接LLM API提供者与用户，创建可持续的生态系统
            </p>
          </div>

          <div className="mt-16 grid gap-10 lg:grid-cols-2">
            {/* For API Providers */}
            <div className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 rounded-lg p-8 shadow-lg border border-gray-700">
              <h3 className="text-2xl font-bold text-white flex items-center">
                <Zap size={24} className="mr-2 text-purple-400" />
                API提供者（矿工）
              </h3>
              <ul className="mt-6 space-y-4">
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-6 w-6 rounded-full bg-purple-500 text-white text-xs">
                      1
                    </div>
                  </div>
                  <p className="ml-3 text-gray-300">向Canister充值ICP转化为Cycle</p>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-6 w-6 rounded-full bg-purple-500 text-white text-xs">
                      2
                    </div>
                  </div>
                  <p className="ml-3 text-gray-300">提供DeepSeek等LLM API密钥</p>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-6 w-6 rounded-full bg-purple-500 text-white text-xs">
                      3
                    </div>
                  </div>
                  <p className="ml-3 text-gray-300">获得AIM代币奖励</p>
                </li>
              </ul>
              <div className="mt-8">
                <a href="#provider" className="inline-flex items-center text-purple-400 hover:text-purple-300">
                  了解更多矿工机制 <ArrowRight size={16} className="ml-2" />
                </a>
              </div>
            </div>

            {/* For Users */}
            <div className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 rounded-lg p-8 shadow-lg border border-gray-700">
              <h3 className="text-2xl font-bold text-white flex items-center">
                <Users size={24} className="mr-2 text-blue-400" />
                API用户
              </h3>
              <ul className="mt-6 space-y-4">
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-6 w-6 rounded-full bg-blue-500 text-white text-xs">
                      1
                    </div>
                  </div>
                  <p className="ml-3 text-gray-300">消耗AIM代币或向Canister充值ICP作为Cycle</p>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-6 w-6 rounded-full bg-blue-500 text-white text-xs">
                      2
                    </div>
                  </div>
                  <p className="ml-3 text-gray-300">通过Canister访问高质量LLM API</p>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-6 w-6 rounded-full bg-blue-500 text-white text-xs">
                      3
                    </div>
                  </div>
                  <p className="ml-3 text-gray-300">使用服务同时获得AIM代币奖励</p>
                </li>
              </ul>
              <div className="mt-8">
                <a href="#user" className="inline-flex items-center text-blue-400 hover:text-blue-300">
                  了解更多用户机制 <ArrowRight size={16} className="ml-2" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* AIM Token Section */}
      <section id="token" className="py-20 bg-gray-800/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-500 sm:text-4xl">
              AIM 代币经济
            </h2>
            <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-300">
              AIM代币推动整个生态系统的增长与参与
            </p>
          </div>

          <div className="mt-16 bg-gray-900 rounded-xl p-8 shadow-xl border border-gray-700">
            <div className="grid gap-10 lg:grid-cols-2">
              <div>
                <h3 className="text-2xl font-bold text-white flex items-center">
                  <DollarSign size={24} className="mr-2 text-green-400" />
                  代币功能
                </h3>
                <ul className="mt-6 space-y-4">
                  <li className="flex items-start">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-6 w-6 rounded-full bg-green-500 text-white text-xs">
                        ✓
                      </div>
                    </div>
                    <p className="ml-3 text-gray-300">支付平台上的AI服务访问费用</p>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-6 w-6 rounded-full bg-green-500 text-white text-xs">
                        ✓
                      </div>
                    </div>
                    <p className="ml-3 text-gray-300">奖励API提供者和平台用户</p>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-6 w-6 rounded-full bg-green-500 text-white text-xs">
                        ✓
                      </div>
                    </div>
                    <p className="ml-3 text-gray-300">平台治理和未来功能投票</p>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-6 w-6 rounded-full bg-green-500 text-white text-xs">
                        ✓
                      </div>
                    </div>
                    <p className="ml-3 text-gray-300">质押获得额外奖励和特权</p>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-2xl font-bold text-white flex items-center">
                  <PieChart size={24} className="mr-2 text-yellow-400" />
                  代币分配
                </h3>
                <div className="mt-6 h-64 w-full bg-gray-800 rounded-lg flex items-center justify-center">
                  <div className="text-center text-gray-400">
                    <p>代币分配图表</p>
                    <p className="text-sm mt-2">（开发中）</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Roadmap Section */}
      <section id="roadmap" className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-500 sm:text-4xl">
              项目路线图
            </h2>
            <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-300">
              AI Miner项目发展计划
            </p>
          </div>

          <div className="mt-16 relative">
            {/* Vertical line */}
            <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-gradient-to-b from-purple-500 via-blue-500 to-purple-500"></div>

            <div className="space-y-12">
              {/* Phase 1 */}
              <div className="relative">
                <div className="md:flex items-center">
                  <div className="md:w-1/2 pr-8 md:text-right">
                    <div className="bg-gray-900 p-6 rounded-lg shadow-lg md:ml-auto md:mr-0 border border-gray-700">
                      <h3 className="text-xl font-bold text-white">第一阶段：基础建设</h3>
                      <p className="mt-2 text-gray-300">
                        开发核心平台功能，包括Canister开发、与DeepSeek API集成、AIM代币设计与发布。
                      </p>
                      <div className="mt-4 text-purple-400">2025年第二季度</div>
                    </div>
                  </div>
                  <div className="hidden md:block w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 rounded-full bg-purple-500 border-4 border-gray-900 z-10"></div>
                  <div className="md:w-1/2 pl-8">
                    {/* Empty space for alignment */}
                  </div>
                </div>
              </div>

              {/* Phase 2 */}
              <div className="relative">
                <div className="md:flex items-center">
                  <div className="md:w-1/2 pr-8 md:text-right">
                    {/* Empty space for alignment */}
                  </div>
                  <div className="hidden md:block w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 rounded-full bg-blue-500 border-4 border-gray-900 z-10"></div>
                  <div className="md:w-1/2 pl-8">
                    <div className="bg-gray-900 p-6 rounded-lg shadow-lg border border-gray-700">
                      <h3 className="text-xl font-bold text-white">第二阶段：生态扩展</h3>
                      <p className="mt-2 text-gray-300">
                        拓展支持更多LLM API服务，完善矿工和用户奖励机制，增加社区治理功能。
                      </p>
                      <div className="mt-4 text-blue-400">2025年第三季度</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Phase 3 */}
              <div className="relative">
                <div className="md:flex items-center">
                  <div className="md:w-1/2 pr-8 md:text-right">
                    <div className="bg-gray-900 p-6 rounded-lg shadow-lg md:ml-auto md:mr-0 border border-gray-700">
                      <h3 className="text-xl font-bold text-white">第三阶段：功能完善</h3>
                      <p className="mt-2 text-gray-300">
                        开发高级API调用功能、模型微调服务、数据分析工具，增强用户体验。
                      </p>
                      <div className="mt-4 text-purple-400">2025年第四季度</div>
                    </div>
                  </div>
                  <div className="hidden md:block w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 rounded-full bg-purple-500 border-4 border-gray-900 z-10"></div>
                  <div className="md:w-1/2 pl-8">
                    {/* Empty space for alignment */}
                  </div>
                </div>
              </div>

              {/* Phase 4 */}
              <div className="relative">
                <div className="md:flex items-center">
                  <div className="md:w-1/2 pr-8 md:text-right">
                    {/* Empty space for alignment */}
                  </div>
                  <div className="hidden md:block w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 rounded-full bg-blue-500 border-4 border-gray-900 z-10"></div>
                  <div className="md:w-1/2 pl-8">
                    <div className="bg-gray-900 p-6 rounded-lg shadow-lg border border-gray-700">
                      <h3 className="text-xl font-bold text-white">第四阶段：全球扩张</h3>
                      <p className="mt-2 text-gray-300">
                        拓展全球市场，与更多AI服务商建立合作关系，打造完整的AI服务生态系统。
                      </p>
                      <div className="mt-4 text-blue-400">2026年第一季度</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-purple-900 to-blue-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              加入AI Miner生态系统
            </h2>
            <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-300">
              无论您是API提供者还是用户，都可以参与并从中获益
            </p>
            <div className="mt-10 sm:flex sm:justify-center">
              <div className="rounded-md shadow">
                <a
                  href="#getstarted"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-900 bg-white hover:bg-gray-100 md:py-4 md:text-lg md:px-10"
                >
                  立即开始
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="#contact"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 md:py-4 md:text-lg md:px-10"
                >
                  联系我们
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-bold text-white">AI Miner</h3>
              <p className="mt-2 text-gray-400">
                基于Internet Computer的去中心化人工智能访问平台
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold text-white">探索</h4>
              <ul className="mt-4 space-y-2">
                <li><a href="#features" className="text-gray-400 hover:text-white">特性</a></li>
                <li><a href="#ecosystem" className="text-gray-400 hover:text-white">生态系统</a></li>
                <li><a href="#token" className="text-gray-400 hover:text-white">AIM代币</a></li>
                <li><a href="#roadmap" className="text-gray-400 hover:text-white">路线图</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold text-white">资源</h4>
              <ul className="mt-4 space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">文档</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">API参考</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">社区</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">博客</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold text-white">联系我们</h4>
              <ul className="mt-4 space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Twitter</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Discord</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Telegram</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">GitHub</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-800 pt-8">
            <p className="text-gray-400 text-center">
              &copy; 2025 AI Miner. 保留所有权利。
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
